import React from 'react'

const Navbar = () => {
  return (
    <div>
        <body><nav>
        <ul class='nav-bar'>
            <li className='text-#2F3C7E ml-10 mt-10'>___Shajin.</li>
            <input type='checkbox' id='check' />
            <span class="menu">
                <li className='mt-10'><a href="">Home</a></li>
                <li className='mt-10'><a href="">Projects</a></li>
                <li className='mt-10'><a href="">Timeline</a></li>
                <li className='mt-10'><a href="">Services</a></li>
                <li className='mt-10'><a href="">Technologies</a></li>
                <li className='mt-10'><a href="">Contact</a></li>
                <li className='mt-10 mr-10'><a href="">CV</a></li>

                <label for="check" class="close-menu"><i class="fas fa-times"></i></label>
            </span>
            <label for="check" class="open-menu"><i class="fas fa-bars"></i></label>
        </ul> 
    </nav></body>
        
    </div>
  )
}

export default Navbar