import Navbar from './Components/Navbar';
import Prim_Info from './Components/Prim_Info';
import Timeline from './Components/Timeline';

function App() {
  return (
    <div>
      <Navbar></Navbar>
      <Prim_Info></Prim_Info>
      <Timeline></Timeline>
    </div>
  );
}

export default App;
