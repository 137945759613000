import React from 'react'

const Prim_Info = () => {
  return (
    <div className='par_div   bg-[#DCF2F1] w-full h-[600px] inline-block'>
        {/* 
        <p class="text "> I'm a Backend Developer.</p> */}
        
        <div class="animated-text ml-10 mt-10">
        <p className='mb-5'>Hi I'm Shajin</p>
          a <span></span>
        </div>
    </div>
    
  )
}

export default Prim_Info 