import React from 'react'

const Timeline = () => {
  return (
    <div>
        <section class="timeline-section">
		
	<div class="timeline-items">
		<div class="timeline-item">
			<div class="timeline-dot"></div>
			<div class="timeline-date">2023 - present</div>
			<div class="timeline-content">
				<h3>Scalar Academy Bangalore India</h3>
				<p>Software Development & Problem Solving. </p>
			</div>
		</div>
		<div class="timeline-item">
			<div class="timeline-dot"></div>
			<div class="timeline-date">2021 - present</div>
			<div class="timeline-content">
				<h3>Elite Tech Park, Coimbatore, India.</h3>
				<p>Software Development Engineer (Backend). </p>
			</div>
		</div>
		<div class="timeline-item">
			<div class="timeline-dot"></div>
			<div class="timeline-date">2017 - 2020</div>
			<div class="timeline-content">
				<h3>Kongo Engineering College Erode India.</h3>
				<p>Bsc(Software Systems). </p>
			</div>
		</div>
		<div class="timeline-item">
			<div class="timeline-dot"></div>
			<div class="timeline-date">2015 - 2017</div>
			<div class="timeline-content">
				<h3>Bharathi Vijayamangalam India</h3>
				<p>High School. </p>
			</div>
		</div>
		<div class="timeline-item">
			<div class="timeline-dot"></div>
			<div class="timeline-date">2019</div>
			<div class="timeline-content">
				<h3>NRKN Kunnathur India</h3>
				<p>High School. </p>
			</div>
		</div>
	</div>
</section>
    </div>
  )
}

export default Timeline